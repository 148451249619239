import * as React from 'react'

import {
  ContentItem,
  ProductBlock,
  ProductBlockProps,
} from '@thg-commerce/enterprise-components'
import {
  HorizontalAlignment,
  i18n,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import {
  FreeTextProductPersonalisationField,
  SingleSelectionProductPersonalisationField,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { FulfilmentMethod } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { ColourChoice } from '@thg-commerce/gravity-elements/Swatch'
import {
  Carousel,
  CarouselButtonPlacement,
  TextStyling,
} from '@thg-commerce/gravity-patterns'
import { PictureProps } from '@thg-commerce/gravity-system'

export interface ProductProps extends Omit<ProductBlockProps, 'review'> {
  url: string
  image?: {
    urls: {
      largeProduct: string
    }
    altText: string
    isAmp: boolean
    lazy?: boolean
  }
  hoverImage?: {
    urls: {
      largeProduct: string
      thumbnail?: string
    }
    altText: string
    isAmp: boolean
    lazy?: boolean
  }
  review?: {
    starRating: number
    numberOfReviews: number
  } | null
  picture?: PictureProps
  hoverPicture?: PictureProps
  externalIdentifier?: string
  eligibleForFulfilmentMethods?: FulfilmentMethod
  inStock: boolean
  isCheckStock: boolean
  colourSwatches?: ColourChoice[]
  isOrderInStore: boolean
  icons?: {
    homeDelivery: boolean
    storeAvailable: boolean
    storeDelivery: boolean
  }
  personalisationFields?: [
    | FreeTextProductPersonalisationField
    | SingleSelectionProductPersonalisationField,
  ]
}

export interface BestSellersProps {
  title?: {
    text: string
    font: TextStyling
    horizontalAlignment?: HorizontalAlignment
  }
  shouldDisplayTitle?: boolean
  subtitle?: {
    text: string
    marginBottom?: number
    horizontalAlignment?: HorizontalAlignment
  }
  productBlocks: ProductProps[]
  className?: string
  widgetIndex: number
  fulfilmentMethodsText: {
    clickAndCollect: {
      isAvailable: string
      isNotAvailable: string
    }
    homeDelivery: {
      isAvailable: string
      isNotAvailable: string
      isOutOfStock: string
    }
    storeDelivery: {
      isAvailable: string
    }
  }
  displayQuickBuyButton?: boolean
  showPdpLinkWhenOutOfStock?: boolean
  content?: ContentItem[]
}

const Title = styled.h2<{ horizontalAlignment?: HorizontalAlignment }>`
  ${(props) =>
    Text(
      props.theme.widget.bestSellers.title.font.entry,
      props.theme.widget.bestSellers.title.font.style,
      props.theme.widget.bestSellers.title.font.hasScaledText,
    )};
  text-transform: ${(props) =>
    props.theme.widget.bestSellers.title.font.transform};
  color: ${(props) => props.theme.widget.bestSellers.title.font.textColor};
  text-decoration: ${(props) =>
    props.theme.widget.bestSellers.title.font.textDecoration};
  text-align: ${(props) => props.horizontalAlignment || 'center'};
  margin-top: ${spacing(3)};
`

const SubTitle = styled.h2<{ horizontalAlignment?: HorizontalAlignment }>`
  ${(props) =>
    Text(
      props.theme.widget.bestSellers.subtitle.font.entry,
      props.theme.widget.bestSellers.subtitle.font.style,
    )};
  text-align: ${(props) => props.horizontalAlignment || 'center'};
  margin-top: ${spacing(1)};
`

const CarouselContainer = styled.div`
  margin-top: ${spacing(3)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: ${spacing(4)};
  }
`

export const BestSellers = (props: BestSellersProps) => {
  const { displayQuickBuyButton = true } = props
  const theme = useTheme()
  const {
    hasClickAndCollect,
    showMarketedSpecialOfferIcon,
    hideProductListImageRollover,
  } = useSiteConfig()
  const i18nText = {
    carouselText: {
      leftScrollLabel: i18n('carousel.controls.left.arialabel'),
      rightScrollLabel: i18n('carousel.controls.right.arialabel'),
    },
    personalisedBuyText: i18n('personalisation.productbuynow.text'),
    buyAriaLabel: i18n('general.productbuynow.label'),
    quickBuyText: i18n('general.product.quickbuy.text'),
    buyText: i18n('general.productbuynow.text'),
    reviewsStarsLabel: i18n('reviews.stars.label'),
    reviewsLabel: i18n('reviews.reviews.label'),
    soldOutText: i18n('general.productsoldout.text'),
    productI18nText: {
      closeI18nText: {
        closeAriaLabel: i18n('general.modal.close.button.arialabel'),
        closeLabel: i18n('general.modal.close.button.label'),
      },
      freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
    },
    fulfilmentMethodsText: {
      clickAndCollect: {
        isAvailable: i18n('product.list.clickandcollect.text'),
        isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
      },
      homeDelivery: {
        isAvailable: i18n('product.list.delivery.available'),
        isNotAvailable: i18n('product.delivery.home.unavailable.text'),
        isOutOfStock: i18n('clickandcollect.store.availability.outofstock'),
        nextDayDelivery: i18n('product.list.item.nextdaydelivery'),
        oneManDelivery: i18n(
          'product.list.fulfilment.1man.nextdaydeliveryavailable',
        ),
        outOfGaugeDelivery: i18n(
          'product.list.fulfilment.outofgauge.nameddaydeliveryavailable',
        ),
      },
      storeDelivery: {
        isAvailable: i18n('product.delivery.store.available.text'),
      },
      orderInStore: {
        isAvailable: i18n(
          'product.item.fulfilment.orderinstore.available.text',
        ),
      },
    },
  }

  const screenReaderReviewText = ({ score, total }) =>
    `${score} ${i18nText.reviewsStarsLabel} ${total} ${i18nText.reviewsLabel}`

  const dynamicDelivery = (weightGroups) => {
    return i18n(`product.item.fulfilment.${weightGroups}`)
  }

  const productBlockTitle = (product: ProductProps) => {
    return !displayQuickBuyButton || product.inStock
      ? product?.personalisationFields &&
        product.personalisationFields?.length > 0
        ? i18nText.personalisedBuyText
        : i18nText.buyText
      : i18nText.soldOutText
  }

  const productBlocks = props.productBlocks
    .filter((product) => product.sku)
    .map((product, index) => (
      <ProductBlock
        key={index}
        externalIdentifier={product.externalIdentifier}
        eligibleForFulfilmentMethods={product.eligibleForFulfilmentMethods}
        {...product}
        marketedSpecialOffer={
          product.marketedSpecialOffer
            ? {
                ...product.marketedSpecialOffer,
                title: product.marketedSpecialOffer?.title || '',
                description: product.marketedSpecialOffer?.description,
                loading: false,
                onlyDisplayOfferBadge: true,
                showIcon: showMarketedSpecialOfferIcon,
                i18nText: {
                  closeI18nText: i18nText.productI18nText.closeI18nText,
                  freeGiftMessage: i18nText.productI18nText.freeGiftMessage,
                },
              }
            : undefined
        }
        image={
          product.image && {
            ...product.image,
            lazy: props.widgetIndex > 1 ? true : product.image?.lazy,
          }
        }
        hoverImage={
          !hideProductListImageRollover && product.hoverImage
            ? {
                ...product.hoverImage,
                lazy: props.widgetIndex > 1 ? true : product.hoverImage.lazy,
              }
            : undefined
        }
        picture={
          product.picture?.sources
            ? {
                ...product.picture,
                lazy: props.widgetIndex > 1 ? true : product.image?.lazy,
              }
            : undefined
        }
        review={
          product.review
            ? {
                ...product.review,
                screenReaderOnlyText: screenReaderReviewText({
                  score: product.review?.starRating,
                  total: product.review?.numberOfReviews,
                }),
              }
            : undefined
        }
        button={{
          title: productBlockTitle(product),
          ariaLabel: i18nText.buyAriaLabel,
          quickBuyTitle: i18nText.quickBuyText,
          productInStock: product.inStock,
          disabled: !product.inStock ?? !props.showPdpLinkWhenOutOfStock,
        }}
        fulfilmentMethodIconsProps={{
          hasClickAndCollect,
          isCheckStock: product.isCheckStock,
          isOrderInStore: product.isOrderInStore,
          inStockLocations: product.inStockLocations,
          leadTime: product.leadTime,
          isBookable: product.isBookable,
          weightGroups: product.weightGroups,
          inStock: product.inStock,
          iconsAvailability: product.icons,
          i18nText: {
            ...i18nText.fulfilmentMethodsText,
            homeDelivery: {
              ...i18nText.fulfilmentMethodsText.homeDelivery,
              datedDelivery: i18n(
                'basket.item.fulfilment.leadtime',
                product.leadTime?.toString(),
              ),
              dynamicDelivery: dynamicDelivery(
                product.weightGroups?.[0]?.toLowerCase(),
              ),
            },
          },
        }}
        displayQuickBuyButton={displayQuickBuyButton}
        content={product?.content}
        brand={{
          name: product.brand?.name || '',
          imageUrl: product.brand?.imageUrl,
        }}
      />
    ))
  const carouselBreakpoints = {
    2: [2, 2, 2, 2],
    3: [1, 2, 3, 3],
    default: [1, 2, 4, 4],
  }

  if (props.productBlocks?.length === 0) return null

  return (
    <div className={props.className}>
      {props.title?.text && props.title.text.length > 2 ? (
        <Title horizontalAlignment={props.title?.horizontalAlignment}>
          {props.title.text}
        </Title>
      ) : null}
      {props.subtitle && (
        <SubTitle horizontalAlignment={props.subtitle.horizontalAlignment}>
          {props.subtitle.text}
        </SubTitle>
      )}
      <CarouselContainer>
        <Carousel
          items={productBlocks}
          i18n={i18nText.carouselText}
          itemsPerSlide={
            carouselBreakpoints[productBlocks?.length] ||
            carouselBreakpoints.default
          }
          hideControlsOnSingleSlide={true}
          inactiveSlideFullOpacity={true}
          buttonPlacement={CarouselButtonPlacement.Split}
          indicatorStyle={theme.widget.bestSellers.carousel.indicatorStyle}
          enableSlidePreview={true}
          controls={{
            placement: theme.widget.bestSellers.carousel.controls?.placement,
          }}
        />
      </CarouselContainer>
    </div>
  )
}
