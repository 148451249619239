import { ColourSwatch, ProductOptionKeys } from '../../../types/enterpriseTypes'
import { InStockLocation } from '../../generated/graphql'

interface Product {
  sku: string
  options: {
    key: string
    choices: {
      colour?: string
      key: string
      title: string
    }[]
  }[]
  content?: {
    key: string
    value: {
      stringValue: string
      __typename: 'ProductContentStringValue'
    }
  }[]
  variants: {
    title: string
    inStockLocations?: string[]
    choices: {
      key: string
      optionKey: string
    }[]
    content?: {
      key: string
      value: {
        stringValue: string
        __typename: 'ProductContentStringValue'
      }
    }[]
    inStock: boolean
    product?: {
      sku: string
      url?: string
    } | null
  }[]
}

export const getColour = (product: Product) =>
  `#${
    product?.content?.find((contentKey) => contentKey.key === 'colourHexCode')
      ?.value.stringValue
  }`

export const findOption = (product: Product, optionKey: string) => {
  return product.options.find((option) => option.key === optionKey)
}

export const getColourSwatches = <ProductType extends Product>(
  product: ProductType | undefined,
  alternateProductKeysForSwatches?: string | undefined,
  departmentValue?: string | undefined,
): ColourSwatch[] => {
  if (!product || !product.options) {
    return []
  }
  const mainProductSku = product.sku
  const optionType = alternateProductKeysForSwatches
    ? findOption(product, ProductOptionKeys.COLOUR) ??
      findOption(product, ProductOptionKeys.SIZE)
    : findOption(product, ProductOptionKeys.COLOUR)
  const getProductDepartment = (productContent) => {
    if (!alternateProductKeysForSwatches) {
      return null
    }
    return product.options.find(
      (option) =>
        option.key === ProductOptionKeys.SIZE &&
        productContent
          ?.find((contentKey) =>
            alternateProductKeysForSwatches.includes(contentKey.key),
          )
          ?.value.stringValue.toLowerCase() === departmentValue?.toLowerCase(),
    )
  }
  return (
    // TODO: CSBOM-405 to revisit the swatches logic implementation
    optionType?.choices.reduce(
      (swatches: NonNullable<ColourSwatch[]>, colourChoice) => {
        const currentVariant = product.variants.find(
          (variant) =>
            variant.inStock &&
            variant.choices.find((choice) => choice.key === colourChoice.key),
        )
        const href = currentVariant?.product?.url
        const productContent = product.variants.find((variant) =>
          variant.content?.find((contentKey) =>
            contentKey.key.includes('colourHexCode'),
          ),
        )?.content
        const currentProduct = currentVariant && {
          sku: currentVariant.product?.sku,
          colour: getColour(currentVariant),
        }
        if (
          alternateProductKeysForSwatches &&
          colourChoice.colour === null &&
          getProductDepartment(productContent) &&
          (currentVariant?.inStockLocations?.includes(
            InStockLocation.Warehouse,
          ) ||
            currentVariant?.inStockLocations?.includes(InStockLocation.Store))
        ) {
          if (currentProduct && currentProduct.sku === mainProductSku) {
            colourChoice.colour = currentProduct.colour
          }
        }
        if (href && colourChoice.colour) {
          const swatch = {
            ...colourChoice,
            href,
            colour: colourChoice.colour,
            __typename: 'ColourSwatch',
          }

          if (colourChoice.colour === 'Multi') {
            swatches.unshift(swatch)
          } else {
            swatches.push(swatch)
          }
        }
        return swatches
      },
      [],
    ) || []
  )
}
